import { useState } from "react";

const RegisterForm = ({ registerApi }) => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
    unique_number: "",
    pan: "",
  });
  const [error, setError] = useState("");

  const submitForm = (e) => {
    e.preventDefault();

    const hasEmptyField = Object.values(user).some(
      (field) => field.trim() === ""
    );

    if (hasEmptyField) {
      setError("All fields are mandatory");
      return;
    } else {
      setError("");
    }

    registerApi(user);

    setUser({
      name: "",
      email: "",
      password: "",
      mobile: "",
      unique_number: "",
      pan: "",
    });
  };

  return (
    <form className="row my-4" onSubmit={submitForm}>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="name" className="form-label mb-0">
          Name*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="text"
            className="form-control border-0 input-field bg-transparent"
            id="name"
            name="name"
            placeholder="Name"
            required
            value={user.name}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <i className="bi bi-person-fill position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="email" className="form-label mb-0">
          Email*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="email"
            className="form-control border-0 input-field bg-transparent"
            id="email"
            name="email"
            placeholder="Email"
            required
            value={user.email}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <i className="bi bi-envelope-fill position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="password" className="form-label mb-0">
          Password*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="password"
            className="form-control border-0 input-field bg-transparent"
            id="password"
            name="password"
            placeholder="Password"
            required
            value={user.password}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, password: e.target.value }))
            }
          />
          <i className="bi bi-lock-fill position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="mobile" className="form-label mb-0">
          Mobile No.*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="text"
            className="form-control border-0 input-field bg-transparent"
            id="mobile"
            name="mobile"
            placeholder="Mobile Number"
            required
            value={user.mobile}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, mobile: e.target.value }))
            }
          />
          <i className="bi bi-telephone-fill position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="unique_number" className="form-label mb-0">
          Unique Key*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="text"
            className="form-control border-0 input-field bg-transparent"
            id="unique_number"
            name="unique_number"
            placeholder="Unique Key"
            required
            value={user.unique_number}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, unique_number: e.target.value }))
            }
          />
          <i className="bi bi-hash position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      <div className="col-12 col-sm-4 mb-2">
        <label htmlFor="pan" className="form-label mb-0">
          PAN No.*
        </label>
        <div className="d-flex align-items-center position-relative border border-1 input-area">
          <input
            type="text"
            className="form-control border-0 input-field bg-transparent"
            id="pan"
            name="pan"
            placeholder="PAN Number"
            required
            value={user.pan}
            onChange={(e) =>
              setUser((prev) => ({ ...prev, pan: e.target.value }))
            }
          />
          <i className="bi bi-hash position-absolute fs-5 email-icon text-secondary"></i>
        </div>
      </div>
      {error && <p className="text-danger small mb-2">{error}</p>}
      <div className="col-12 col-sm-4">
        <button type="submit" className="w-100 btn btn-primary mt-3">
          Register User
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
