import { useState } from "react";
import "./pnlModal.css";

const PnlFormModal = ({ open, onClose, addRecord, editRecord, users }) => {
  const [pnlState, setPnlState] = useState({
    name: open.data?.client_name || "",
    date: open.data?.date || "",
    dateEquity: open.data?.as_on_equity || "",
    dateMf: open.data?.as_on_mf || "",
    dateCurrency: open.data?.as_on_currency || "",
    dateFno: open.data?.as_on_fno || "",
    gainsEquity: open.data?.equity || "",
    gainsCurrency: open.data?.currency || "",
    gainsMf: open.data?.mf || "",
    gainsFno: open.data?.fno || "",
    netBooked: open.data?.net_booked || "",
    sum: open.data?.sum || "",
  });
  const [error, setError] = useState("");

  if (!open.open) return null;

  const formSubmit = (e) => {
    e.preventDefault();

    const hasEmptyField = Object.values(pnlState).some(
      (value) => String(value).trim() === ""
    );

    if (hasEmptyField) {
      setError("All fields are mandatory*");
      return;
    } else {
      setError("");
    }

    if (open.data?.id) editRecord(pnlState, open.data?.id);
    else addRecord(pnlState);
    onClose();
  };

  return (
    <div onClick={onClose} className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modalContainer"
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="modal-heading">PTR Form</h4>
        <form className="p-3 row mx-0" onSubmit={formSubmit}>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="client-name" className="form-label mb-0">
              Client Name*
            </label>
            <div className="border border-1 input-area">
              <select
                name="client-name"
                id="client-name"
                className={`form-control border-0 bg-transparent ${
                  open.data?.client_name && "disabled"
                }`}
                style={{ cursor: open.data?.client_name && "not-allowed" }}
                value={pnlState.name}
                onChange={(e) =>
                  setPnlState((prev) => ({ ...prev, name: e.target.value }))
                }
                required
                disabled={open.data?.client_name ? true : false}
              >
                <option value="">Select Client</option>
                {users.length > 0
                  ? users.map((user, index) => (
                      <option value={user.display_name} key={index}>
                        {user.display_name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="date" className="form-label mb-0">
              Date*
            </label>
            <div className="border border-1 input-area">
              <input
                type="date"
                className="form-control border-0 bg-transparent"
                id="date"
                required
                value={pnlState.date}
                onChange={(e) =>
                  setPnlState((prev) => ({ ...prev, date: e.target.value }))
                }
              />
            </div>
          </div>
          <p className="col-12 mb-2 mt-3 fw-bold fs-5 text-center">
            As On Date
          </p>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="date-equity" className="form-label mb-0">
              As On Equity*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="date-equity"
                placeholder="Equity Percentage"
                required
                value={pnlState.dateEquity}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    dateEquity: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="date-mf" className="form-label mb-0">
              As On MF*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="date-mf"
                placeholder="Enter MF"
                required
                value={pnlState.dateMf}
                onChange={(e) =>
                  setPnlState((prev) => ({ ...prev, dateMf: e.target.value }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="date-currency" className="form-label mb-0">
              As On Currency*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="date-currency"
                placeholder="As On Currency"
                required
                value={pnlState.dateCurrency}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    dateCurrency: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="date-fno" className="form-label mb-0">
              As On FNO*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="date-fno"
                placeholder="Enter FNO"
                required
                value={pnlState.dateFno}
                onChange={(e) =>
                  setPnlState((prev) => ({ ...prev, dateFno: e.target.value }))
                }
              />
            </div>
          </div>
          <p className="col-12 mb-2 mt-3 fw-bold fs-5 text-center">
            Capital Gains Booked
          </p>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="gains-equity" className="form-label mb-0">
              Equity*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="gains-equity"
                placeholder="Equity"
                required
                value={pnlState.gainsEquity}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    gainsEquity: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="gains-currency" className="form-label mb-0">
              Currency*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="gains-currency"
                placeholder="Currency"
                required
                value={pnlState.gainsCurrency}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    gainsCurrency: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="gains-mf" className="form-label mb-0">
              MF*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="gains-mf"
                placeholder="Enter MF"
                required
                value={pnlState.gainsMf}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    gainsMf: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="gains-fno" className="form-label mb-0">
              FNO*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="gains-fno"
                placeholder="FNO"
                required
                value={pnlState.gainsFno}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    gainsFno: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="net-booked" className="form-label mb-0">
              Net Booked*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="net-booked"
                placeholder="Net Booked"
                required
                value={pnlState.netBooked}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    netBooked: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="mb-2 col-12 col-sm-6">
            <label htmlFor="sum" className="form-label mb-0">
              Sum*
            </label>
            <div className="border border-1 input-area">
              <input
                type="text"
                className="form-control border-0 bg-transparent"
                id="sum"
                placeholder="Enter Sum"
                required
                value={pnlState.sum}
                onChange={(e) =>
                  setPnlState((prev) => ({
                    ...prev,
                    sum: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {error.length > 0 && (
            <p className="text-danger fw-bold text-end mb-0 col-12">{error}</p>
          )}
          <div className="modalFooter p-2">
            <button type="submit" className="btn modal-btn btn-danger">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PnlFormModal;
