import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AdminNavbar } from "../../layout/adminNavbar";
import { AdminSidebar } from "../../layout/adminSidebar";

const UserPnlRecord = () => {
  const [userRecord, setUserRecord] = useState([]);
  const pnlDataApi = `${process.env.REACT_APP_URL}display-user-pnl-record2`;
  const { id } = useParams();
  const userData = async () => {
    const res = await fetch(pnlDataApi, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    var data = await res.json();
    if (data.status === 200) {
      setUserRecord(data.data);
    }
  };
  useEffect(() => {
    userData();
  }, []);
  return (
    <section className="client-onboarding-container">
      <AdminNavbar />

      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 pt-3">
          <h2>PNL Record</h2>
          <div className="d-flex justify-content-between align-items-center px-3">
            <table className="table table-striped table-bordered table-hover mt-3 small">
              {userRecord.length > 0 ? (
                userRecord.map((record) => (
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <td>{record.date}</td>
                    </tr>
                    <tr>
                      <th>As on Date As On Equity</th>
                      <td>{record.as_on_equity}</td>
                    </tr>
                    <tr>
                      <th>As on MF</th>
                      <td>{record.as_on_mf}</td>
                    </tr>
                    <tr>
                      <th>As On Currency</th>
                      <td>{record.as_on_currency}</td>
                    </tr>
                    <tr>
                      <th>As on FNO</th>
                      <td>{record.as_on_fno}</td>
                    </tr>
                    <tr>
                      <th>Capital ains booked Equity</th>
                      <td>{record.equity}</td>
                    </tr>
                    <tr>
                      <th>Currency</th>
                      <td>{record.currency}</td>
                    </tr>
                    <tr>
                      <th>MF</th>
                      <td>{record.mf}</td>
                    </tr>
                    <tr>
                      <th>Booked FNO</th>
                      <td>{record.fno}</td>
                    </tr>
                    <tr>
                      <th>Net Booked</th>
                      <td>{record.net_booked}</td>
                    </tr>
                    <tr>
                      <th>Sum</th>
                      <td>{record.sum}</td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="12">No Record Found</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserPnlRecord;
