import React, { useCallback, useEffect, useState } from "react";
import { AdminSidebar } from "../../layout/adminSidebar";
import { Loader } from "../../ui/loader/Loader";
import { AdminNavbar } from "../../layout/adminNavbar";
import Footer from "../../layout/Footer";
import PnlTable from "./pnlTable";
import PnlFormModal from "../../ui/pnlModal/pnlFormModal";
import DeleteModal from "../../ui/deleteModal/DeleteModal";
import { RandomId } from "../../utils/randomId";

const PnlRecords = () => {
  const pnlDataApi = `${process.env.REACT_APP_URL}pnl-data`;
  const storePnlDataApi = `${process.env.REACT_APP_URL}pnl-data-store`;
  const deleteRecordApi = `${process.env.REACT_APP_URL}pnl-data-delete`;
  const updateRecordApi = `${process.env.REACT_APP_URL}pnl-data-update`;
  const userListApi = `${process.env.REACT_APP_URL}user-list`;

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const [pnlRecords, setPnlRecords] = useState([]);
  const [users, setUsers] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    type: "PNL record",
  });

  const getPnlData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(pnlDataApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
      if (data.status === 200) {
        setPnlRecords(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Sorry, something went wrong " + error.message);
    }
  }, [pnlDataApi]);

  const getUserList = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await fetch(userListApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      var data = await res.json();
      if (data.status === 200) {
        setUsers(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Sorry, something went wrong " + error.message);
    }
  }, [userListApi]);

  useEffect(() => {
    const fetchData = setTimeout(() => {
      getPnlData();
      getUserList();
    });
    return () => {
      clearTimeout(fetchData);
    };
  }, [getPnlData, getUserList]);

  const addRecord = useCallback(
    async (formData) => {
      try {
        const res = await fetch(storePnlDataApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            client_name: formData.name,
            date: formData.date,
            as_on_equity: formData.dateEquity,
            as_on_mf: formData.dateMf,
            as_on_currency: formData.dateCurrency,
            as_on_fno: formData.dateFno,
            booked_equity: formData.gainsEquity,
            booked_currency: formData.gainsCurrency,
            booked_mf: formData.gainsMf,
            booked_net_booked: formData.netBooked,
            booked_sum: formData.sum,
            booked_fno: formData.gainsFno,
          }),
        });
        var data = await res.json();
        if (data.status === 200) {
          getPnlData();
        }
      } catch (error) {
        alert("Sorry, something went wrong " + error.message);
      }
    },
    [storePnlDataApi, getPnlData]
  );

  const editRecord = useCallback(
    async (formData, id) => {
      try {
        const res = await fetch(updateRecordApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            id: id,
            client_name: formData.name,
            date: formData.date,
            as_on_equity: formData.dateEquity,
            as_on_mf: formData.dateMf,
            as_on_currency: formData.dateCurrency,
            as_on_fno: formData.dateFno,
            booked_equity: formData.gainsEquity,
            booked_currency: formData.gainsCurrency,
            booked_mf: formData.gainsMf,
            booked_net_booked: formData.netBooked,
            booked_sum: formData.sum,
            booked_fno: formData.gainsFno,
          }),
        });
        var data = await res.json();
        if (data.status === 200) {
          getPnlData();
        }
      } catch (error) {
        alert("Sorry, something went wrong " + error.message);
      }
    },
    [updateRecordApi, getPnlData]
  );

  const deleteRecord = useCallback(
    async (id) => {
      setDeleteModal((prev) => ({ ...prev, open: false, id: null }));
      try {
        const res = await fetch(deleteRecordApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            id: id,
          }),
        });
        var data = await res.json();
        if (data.status === 200) {
          getPnlData();
        }
      } catch (error) {
        alert("Sorry, something went wrong " + error.message);
      }
    },
    [deleteRecordApi, getPnlData]
  );

  const handleDeleteModal = (id) => {
    setDeleteModal((prev) => ({ ...prev, open: true, id }));
  };

  return (
    <section className="client-onboarding-container">
      <AdminNavbar />
      {isLoading ? (
        <div className="d-flex justify-content-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          <div className="row m-0">
            <div className="col-12 col-lg-2 p-0 bg-primary">
              <AdminSidebar />
            </div>
            <div className="col-12 col-lg-10 pt-3">
              <div className="d-flex justify-content-between align-items-center px-3">
                <h4 className="text-primary mb-0">PNL Records</h4>
                <button
                  className="btn btn-primary me-2"
                  onClick={() => setOpenModal({ open: true, data: null })}
                >
                  PNL Form
                </button>
              </div>
              <PnlTable
                pnlRecords={pnlRecords}
                handleDeleteModal={handleDeleteModal}
                setOpenModal={setOpenModal}
                type="admin"
              />
            </div>
            <PnlFormModal
              key={RandomId()}
              open={openModal}
              onClose={() => setOpenModal(false)}
              addRecord={addRecord}
              editRecord={editRecord}
              users={users}
            />
          </div>
          <Footer />
          <DeleteModal
            key={RandomId()}
            open={deleteModal.open}
            onClose={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
            onDelete={() => deleteRecord(deleteModal.id)}
            type={deleteModal.type}
          />
        </>
      )}
    </section>
  );
};

export default PnlRecords;
