import React, { useEffect, useState } from "react";
import { AdminNavbar } from "../../layout/adminNavbar";
import { AdminSidebar } from "../../layout/adminSidebar";
import { Link } from "react-router-dom";

const PnlUsers = () => {
  const userDataApi = `${process.env.REACT_APP_URL}pnl-users`;
  const [users, setUsers] = useState([]);
  const fetchUsers = async () => {
    const res = await fetch(userDataApi, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    var data = await res.json();
    if (data.status === 200) {
      setUsers(data.data);
      console.log(users);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <section className="client-onboarding-container">
      <AdminNavbar />

      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 pt-3">
          <div className="d-flex justify-content-between align-items-center px-3">
            <div>
              <table className="table table-striped table-bordered table-hover mt-3 small">
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Email</th>
                    <th>Contact Number</th>
                    <th>Unique Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.mobile}</td>
                      <td>{user.display_name}</td>
                      <td>
                        <Link
                          to={`/user-pnl-record/${user.id}`}
                          className="text-primary"
                        >
                          View PNL Record
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PnlUsers;
