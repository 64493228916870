import React from "react";
import moneydhan from "../../assets/moneydhan.png";

const UserNavbar = ({ setNavbar }) => {
  return (
    <div className="d-flex d-lg-none justify-content-between align-items-center px-3">
      <img src={moneydhan} alt="moneydhan-logo" style={{ width: "8rem" }} />
      <i className="fa-solid fa-bars fa-lg" onClick={() => setNavbar(true)}></i>
    </div>
  );
};

export default UserNavbar;
