import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import { deleteUser } from "../../store/slices/UserSlice";
import { useEffect } from "react";

export const AdminNavbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => {
    return state.users.data.role;
  });

  const open = openSidebar
    ? "responsive-sidebar collapse-sidebar"
    : "responsive-sidebar";

  useEffect(() => {
    if (role !== 1) {
      navigate("/");
    }
  }, []);

  const AdminLogout = () => {
    dispatch(deleteUser());
    navigate("/");
  };

  return (
    <nav className="bg-white d-flex justify-content-between align-items-center shadow px-3 px-lg-4">
      <i
        className="fa-solid fa-bars d-lg-none"
        onClick={() => setOpenSidebar(!openSidebar)}
      ></i>
      <div className={open}>
        <p
          className="close-btn text-end pe-2 fw-bold text-light"
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          X
        </p>
        <p className="text-white">
          <i className="bi bi-person me-1"></i>
          Shorabh Chobey
        </p>
        <Link
          to="/dashboard"
          className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
        >
          Dashboard
        </Link>
        <Link
          to="/client-onboarding"
          className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
        >
          Client OnBoarding
        </Link>
        <Link
          to="/document-vault"
          className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
        >
          Document Vault
        </Link>
        <Link
          to="/pnl-records"
          className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
        >
          PNL Records
        </Link>
        <Link
          to="/register-user"
          className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
        >
          Register User
        </Link>
        <div
          className="d-flex mb-0"
          onClick={AdminLogout}
          style={{ cursor: "pointer" }}
        >
          <i className="bi bi-box-arrow-right me-1 text-danger"></i>
          <p className="mb-0 text-white">Logout</p>
        </div>
      </div>
      <Link to="/">
        <img
          src="https://moneydhan.com/wp-content/uploads/2021/02/Moneydhan-Logo-04-1.png"
          alt="logo"
          className="moneydhan-logo"
        />
      </Link>
      <div
        className="d-none d-lg-flex align-items-center"
        onClick={AdminLogout}
        style={{ cursor: "pointer" }}
      >
        <i className="bi bi-box-arrow-right me-1 mb-0 text-danger h3"></i>
        <p className="mb-0 h5">Logout</p>
      </div>
    </nav>
  );
};
