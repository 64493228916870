import { Link, useNavigate } from "react-router-dom";
import moneydhan from "../../assets/moneydhan.png";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../store/slices/UserSlice";

const ResponsiveSidebar = ({ navbar, setNavbar }) => {
  const navigate = useNavigate();
  const fnDispatch = useDispatch();

  const Logout = () => {
    fnDispatch(deleteUser());
    navigate("/");
  };

  return (
    <div
      className={`${
        navbar ? "d-block" : "d-none"
      } d-lg-none position-absolute sidebar pb-3 vh-100 bg-white w-100`}
      style={{ zIndex: 2 }}
    >
      <div
        className="text-center d-flex align-items-center justify-content-between px-3"
        style={{ backgroundColor: "#f4f3f3" }}
      >
        <img src={moneydhan} alt="moneydhan-logo" style={{ width: "8rem" }} />
        <i
          className="fa-solid fa-bars fa-lg"
          onClick={() => setNavbar(false)}
        ></i>
      </div>
      <div className="d-flex align-items-center cstm-hover border-bottom p-3">
        <i className="fa-solid fa-coins fa-lg" style={{ color: "gold" }}></i>
        <Link
          to="/user-dashboard"
          className="d-block ps-2 fw-bold"
          style={{ fontSize: "18px" }}
        >
          Income
        </Link>
      </div>
      <div className="d-flex align-items-center cstm-hover border-bottom p-3">
        <i
          className="fa-solid fa-hand-holding-dollar fa-lg"
          style={{ color: "gold" }}
        ></i>
        <Link
          to="/expenses"
          className="d-block ps-2 fw-bold"
          style={{ fontSize: "18px" }}
        >
          Expenses
        </Link>
      </div>
      <div className="d-flex align-items-center cstm-hover border-bottom p-3">
        <i
          className="fa-solid fa-bars-staggered fa-lg"
          style={{ color: "gold" }}
        ></i>
        <Link
          to="/records"
          className="d-block ps-2 fw-bold"
          style={{ fontSize: "18px" }}
        >
          PNL Records
        </Link>
      </div>
      <button
        className="position-absolute bottom-0 w-100 border-0 text-center p-2"
        style={{
          backgroundColor: "#f4f3f3",
          fontSize: "22px",
          cursor: "pointer",
        }}
        onClick={Logout}
      >
        <i className="bi bi-box-arrow-right me-1 text-danger"></i>
        <span>LogOut</span>
      </button>
    </div>
  );
};

export default ResponsiveSidebar;
