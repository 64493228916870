import React from "react";
import { Link } from "react-router-dom";

export const AdminSidebar = () => {
  return (
    <div className="d-none d-lg-block sidebar py-3">
      <Link
        to="/admin-dashboard"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        Dashboard
      </Link>
      <Link
        to="/client-onboarding"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        Client OnBoarding
      </Link>
      <Link
        to="/document-vault"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        Document Vault
      </Link>
      <Link
        to="/pnl-records"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        PNL Records
      </Link>
      <Link
        to="/register-user"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        Register User
      </Link>
      <Link
        to="/users"
        className="d-block border-bottom text-white cstm-hover py-1 my-2 ps-2"
      >
        Users
      </Link>
    </div>
  );
};
