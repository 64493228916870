import React, { useCallback, useState } from "react";
import Footer from "../../layout/Footer";
import { AdminSidebar } from "../../layout/adminSidebar";
import { AdminNavbar } from "../../layout/adminNavbar";
import RegisterForm from "./registerForm";

const RegisterUser = () => {
  const registrationApi = `${process.env.REACT_APP_URL}registration`;

  const [apiStatus, setApiStatus] = useState({
    status: "",
    message: "",
  });

  const registerApi = useCallback(
    async (user) => {
      setApiStatus({ status: "", message: "" });
      try {
        var res = await fetch(registrationApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: user.name,
            email: user.email,
            password: user.password,
            mobile: user.mobile,
            unique_number: user.unique_number,
            pan_number: user.pan,
          }),
        });
        var data = await res.json();
        if (data.status === 200) {
          setApiStatus({
            status: "success",
            message: "Success! User Registered",
          });
        } else if (data.status === 500) {
          setApiStatus({
            status: "failed",
            message: "Sorry! Failed Registration, User already exists.",
          });
        }
      } catch (error) {
        setApiStatus({
          status: "failed",
          message: "Sorry! Failed Registration",
        });
      }
    },
    [registrationApi]
  );

  return (
    <section className="client-onboarding-container">
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-lg-2 p-0 bg-primary">
          <AdminSidebar />
        </div>
        <div className="col-12 col-lg-10 pt-3">
          <h4 className="text-primary mb-0">Register User</h4>
          <RegisterForm registerApi={registerApi} />
          {apiStatus && (
            <p
              className={`${
                apiStatus.status === "success" ? "text-success" : "text-danger"
              }`}
            >
              {apiStatus.message}
            </p>
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default RegisterUser;
